module EditGroup

open System
open Elmish
open Feliz.ElmishComponents
open Feliz
open Feliz.Bulma
open Shared.AddressBook
open Extensions.View

let t = Localization.ns ("editGroup")

type State = {
    Group: Group
    SaveResult: Deferred<Result<unit, string>>
}

type Msg =
    | GroupNameChanged of string
    | CloseWindow
    | SaveGroup of AsyncOperationStatus<Result<unit, string>>

module Cmd =
    let save (group: Group) : Cmd<Msg> =
        async {
            try
                let! result = Communication.addressBookApi().saveGroup group |> Remoting.handleNonAuth
                let result = result |> Result.mapError (Components.Common.TranslatedErrors.ServerError.explainTranslation)
                return SaveGroup (Finished result)
            with
                ex -> return SaveGroup (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

let init group = {Group = group; SaveResult = HasNotStartedYet}, Cmd.none

let update (close: unit -> unit) msg (state: State) =
    match msg with
    | GroupNameChanged name ->
        { state with Group = { state.Group with Name = name } }, Cmd.none
    | CloseWindow ->
        close()
        state, Cmd.none
    | SaveGroup Started ->
        {state with SaveResult = InProgress}, Cmd.save state.Group
    | SaveGroup (Finished (Ok _)) ->
        close()
        {state with SaveResult = Resolved (Ok ())}, Cmd.none
    | SaveGroup (Finished (Error x)) ->
        {state with SaveResult = Resolved (Error x)}, Cmd.toastServerError "Save group" x

let renderToolbar (title: string) state dispatch =
    Bulma.section[
        prop.style[
            style.paddingBottom 0
        ]
        prop.children[
            Bulma.container[
                Bulma.level[
                    Bulma.levelLeft[
                        Bulma.levelItem[
                            prop.classes [BulmaCss.``is-uppercase``]
                            prop.children [Bulma.title.h3 title]
                        ]
                    ]
                    Bulma.levelRight[
                        Bulma.levelItem[
                            Bulma.button.button[
                                prop.onClick(fun x-> x.preventDefault(); dispatch CloseWindow)
                                prop.children[
                                    Bulma.icon[
                                        Html.i[
                                            prop.classes [MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiClose]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let renderForm state dispatch =
    Bulma.section[
        prop.style[style.paddingTop 90]
        prop.children[
            Bulma.container[
                prop.style [style.textAlign.center]
                prop.children[
                    Bulma.input.text[
                        prop.style [style.marginBottom 40]
                        prop.valueOrDefault state.Group.Name
                        prop.placeholder (t "placeholder.groupName")
                        prop.onChange (GroupNameChanged >> dispatch)
                    ]
                    Bulma.button.button[
                        color.isPrimary
                        prop.style[style.width (length.percent 100)]
                        prop.text (t "button.save")
                        prop.onClick (fun x -> x.preventDefault(); dispatch (SaveGroup Started))
                    ]
                ]
            ]
        ]
    ]

let render title state dispatch =
    Bulma.section[
        renderToolbar title state dispatch
        renderForm state dispatch
    ]

let editGroup group onClose=
    React.elmishComponent("EditGroup", init group, update onClose, render (t "title.editGroup"))

let addGroup onClose =
    let newGroup = {
        Id = Guid.NewGuid()
        Name = ""
    }
    React.elmishComponent("AddGroup", init newGroup, update onClose, render (t "title.addGroup"))