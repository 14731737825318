module Resources

let private en = {|
     signin = {|
                ``toast.title`` = "Sign in"
                ``button.login`` = "Login"
                ``email.placeholder`` = "Email"
                ``password.placeholder`` = "Password"
                ``title.forgotpassword`` = "Forgot password?"
                |}
     forgotPassword = {|
                ``toast.success.msg`` = "An email has been sent to your email address."
                ``toast.error.title`` = "Forgot password"
                ``title`` = "Forgot password?"
                ``back.link`` = "Back"
                ``button.send`` = "Send"
                |}
     changePassword = {|
                ``toast.success.msg`` = "The password has now been changed. Try logging in with your new password."
                ``title`` = "Change password"
                ``placeholder.newPassword`` = "Enter new password"
                ``placeholder.repeatPassword`` = "Repeat new password"
                ``button.save`` = "Save"
                ``signIn.link`` = "To sign in"
                ``error.password`` = "Password is required and should contain at least 8 characters"
                ``error.passwordConfirmation`` = "Passwords don't match"
                |};
     errors = {|
               ``server.exception`` = "Ops, something wrong happened. Details: "
               ``server.validation`` = "Field"
               ``server.itemNotFound``="Not found in database"
               ``validation.isEmpty``="should be not empty"
               ``validation.tooLong`` = "should be less than"
               ``validation.tooSmall`` = "should be greater than"
               ``validation.notUnique`` = "should be unique"
               ``validation.isRequired`` = "is required"
               ``validation.invalidFormat`` = "has invalid format"
               ``domain.caseRegistered`` = "Case with given case Id already registered"
               ``server.remoteService`` = "Problem with third party services"
               ``domain.videoNotStarted`` = "Video recording with given Id has not started yet."
               ``domain.videoFileShouldBeCreated`` = "Case file for video recording with Id should be created before."
               ``domain.passwordInvalid`` = "Invalid user or password"
               ``domain.AccountLocked`` = "Account locked"
               ``domain.AccountEmailAlreadyRegistered`` = "Account with given email already registered"
               ``domain.OldPasswordNotMatchToDbPassword`` = "Current password is invalid"
                |}
     addressBook = {|
                    ``group.ungroupedContacts`` = "All ungrouped contacts"
                    ``deviceId.placeholder`` = "Device ID"
                    ``selectCase.prompt`` = "Select case"
                    ``search.case.info`` = "Shown {{found}} out of {{total}}"
                    ``search.case.noItemsFound`` = "No cases found"
                    ``search.case.startTypeToSearch`` = "Start type to search"
                    ``search.case.placeholder`` = "Search cases"
                    ``contextMenu.edit`` = "Edit"
                    ``contextMenu.delete`` = "Delete"
                    ``contextMenu.newContact`` = "New Contact"
                    ``contextMenu.newGroup`` = "New Group"
                    ``input.placeholder.enterDeviceId`` = "Enter Device ID"
                    ``table.contacts`` = "contacts"
                    ``loading.groups`` = "Loading groups ... "
                    ``title.addressbook`` = "Address book"
                    ``delete.contact.confirmation.question`` = "Are you sure you want to delete contact?"
                    ``delete.group.confirmation.question`` = "Are you sure you want to delete group?"
                   |}
     emergencyCases = {|
                        ``title`` = "Emergency cases"
                        ``loading.cases``= "Loading cases ..."
                        ``case.damageConstruction`` = "Construction"
                        ``case.damageMotor`` = "Motor"
                        ``case.damagePrevention`` = "Prevention"
                        ``case.damageOther``= "Other"
                       |}
     editContact = {|
                    ``search.group.info`` = "Shown {{found}} out of {{total}}"
                    ``search.group.noItemsFound`` = "No group found"
                    ``search.group.startTypeToSearch`` = "Start type to search"
                    ``search.group.placeholder`` = "Choose a group"
                    ``title.editContact`` = "Edit contact"
                    ``title.addContact`` = "New contact"
                    ``placeholder.firstName`` = "First name"
                    ``placeholder.lastName`` = "Last name"
                    ``placeholder.deviceId`` = "Device ID"
                    ``button.save`` = "Save"
                   |}
     editGroup = {|
                    ``title.editGroup`` = "Edit group"
                    ``title.addGroup`` = "New group"
                    ``placeholder.groupName`` = "Group name"
                    ``button.save`` = "Save"
               |}
     selectMedia ={|
                    ``failed.access.to.devices`` = "Failed to get access to devices. Error:"
                    ``no.device`` = "No device"
                    ``camera`` = "Camera"
                    ``microphone`` = "Microphone"
                    ``audio.output`` = "Audio output"
                    ``proceed.call`` = "Call"
                    ``proceed.call.with.sharing.link`` = "Call with shared link"
                    ``cancel.call`` = "Cancel"
                    ``verifying.access.to.devices`` = "Verifying access to devices"
               |}
     videoCall ={|
                ``call.end`` = "Call ended"
                ``call.closeTab`` = "Close this tab"
                ``call.toCaseDetails`` = "Back to case details"
                ``call.joining`` = "Joining ..."
                ``call.waiting.of.joining`` = "Waiting client to join ..."
                ``call.button.leave`` = "Leave"
                ``call.device.notAllowed`` = "The grant access to camera or audio resource was refused."
                ``call.device.mediaOptionInvalid`` = "The camera is occupied or the resolution is not supported (on browsers in early versions)."
                ``call.device.notFound`` = "No device is found."
                ``call.device.notSupported`` = "The browser does not support using camera and microphone."
                ``call.device.permissionDenied`` = "The device is disabled by the browser or the user has denied permission of using the device."
                ``call.device.constraintNotSatisfied`` = "The settings are illegal (on browsers in early versions)."
                ``call.device.pluginNotInstalledProperly`` = "A screen-sharing request is made with no plugin installed or with a wrong extensionId on the Chrome browser."
                ``call.device.undefined`` = "Undefined error."
                ``title.email`` = "E-mail"
                ``title.phone`` = "Phone number"
                ``call.button.send.link`` = "Send link"
                ``call.sending.link.success`` = "Link was send successfully"
               |}
     recordingCall = {|
                        ``recording.video.permission`` = "Request permission"
                        ``recoring.video.allowed`` = "Recording allowed"
                        ``recording.start`` = "Start record"
                        ``recording.started`` = "Recording started"
                        ``recording.successfull`` = "Video recorded successfully."
                        ``recording.denied`` = "Recording denied"
                        ``recording.request`` = "Denied. Request again?"
                |}
     photo = {|
                ``photo.allowed`` = "Take photo allowed"
                ``photo.denied`` = "Take photo denied"
                ``photo.taken`` = "Photo taken"
                ``photo.request.permission`` = "Request permission"
                ``photo.take`` = "Take photo"
                ``photo.taking`` = "Taking photo"
                ``photo.denied.request.again`` = "Denied. Request again?"
                ``photo.failed.request.again`` = "Failed request permission. Try again?"
                ``photo.failed.take.again`` = "Failed to take photo. Try again?"
               |}
     case = {|
               ``delete.case.confirmation.question`` = "Are you sure you want to delete case?"
               ``caseStatus.new`` = "New"
               ``caseStatus.emergency`` = "Emergency"
               ``caseStatus.inProgress`` = "Ongoing"
               ``caseStatus.Closed`` = "Closed"
               ``button.addingCase``= "+ New case"
               ``damage.construction``= "Construction"
               ``damage.motor``= "Motor"
               ``damage.prevention``= "Prevention"
               ``damage.other``= "Other"
               ``title.caseNumber``= "Case number"
               ``title.damageCause``= "Cause of damage"
               ``title.inspector``= "Inspector"
               ``title.status``= "Status"
               ``title.date``= "Date"
               ``title.created.date``= "Created date"
               ``title.modified.date``= "Updated date"
               ``loading.cases``= "Loading cases ..."
               |}
     caseDetail = {|
                    ``caseStatus.new`` = "New"
                    ``caseStatus.emergency`` = "Emergency"
                    ``caseStatus.inProgress`` = "In progress"
                    ``caseStatus.Closed`` = "Closed"
                    ``case.report`` = "Case report"
                    ``case.call`` = "Call"
                    ``case.policyholder`` = "insurant"
                    ``case.partner`` = "partner"
                    ``case.damageConstruction`` = "Construction"
                    ``case.damageMotor`` = "Motor"
                    ``case.damagePrevention`` = "Prevention"
                    ``case.damageOther``= "Other"
                    ``case.regnumber`` = "Reg number"
                    ``case.number`` = "Case number"
                    ``case.damageType`` = "Case type"
                    ``case.address`` = "Address"
                    ``case.deviceId`` = "Device Number"
                    ``case.booking`` = "Booked by"
                    ``case.inspector`` = "Inspector"
                    ``case.date`` = "Date"
                    ``case.inspectionDate`` = "Date of inspection"
                    ``case.duration`` = "Duration: "
                    ``case.comment`` = "Add a comment"
                    ``case.created`` = "Case was created"
                    ``case.loadingFiles`` = "Loading files ..."
                    ``case.information`` = "Information"
                    ``case.gallery`` = "Gallery"
                    ``case.historyAndComments`` = "History & Comments"
                    ``case.loading`` = "Loading ..."
                    ``case.media.download`` = "Download"
                    ``case.mediaAddress.processing`` = "Processing address"
                    ``case.mediaAddress.Unknown``= "Unknown address"
                    ``case.mediaAddress.NoLocation`` = "No location"
                    ``warning.no.media`` = "No media files"
                    ``error.downloading.zipFile`` = "Zip File can not be created, some errors occured"
                    ``edit.image.failed.save`` = "Failed to save edited image"
                    ``delete.media.confirmation.question`` = "Are you sure you want to delete the media file?"
                    ``delete.media.confirmation.button.label`` = "Delete"
                    |}
     registerCase = {|
                     ``case.report`` = "Case report"
                     ``button.register`` = "Register"
                     ``case.regnumber`` = "Reg number"
                     ``case.number`` = "Case number"
                     ``case.damageType`` = "Case type"
                     ``case.damageConstruction`` = "Construction"
                     ``case.damageMotor`` = "Motor"
                     ``case.damagePrevention`` = "Prevention"
                     ``case.damageOther``= "Other"
                     ``case.address`` = "Address"
                     ``case.deviceId`` = "Device Number"
                     ``case.inspector`` = "Inspector"
                     ``input.text.noInspectors`` = "No inspector"
                     ``case.date`` = "Date"
                     ``case.inspectionDate`` = "Date of inspection"
                     |}
     editCase = {|
                    ``case.damageConstruction`` = "Construction"
                    ``case.damageMotor`` = "Motor"
                    ``case.damagePrevention`` = "Prevention"
                    ``case.damageOther``= "Other"
                    ``case.edit`` = "Edit case"
                    ``case.save`` = "Save"
                    ``case.regnumber`` = "Reg number"
                    ``case.number`` = "Case number"
                    ``case.damageType`` = "Case type"
                    ``case.address`` = "Address"
                    ``case.deviceId`` = "Device Number"
                    ``case.inspector`` = "Inspector"
                    ``case.date`` = "Date"
                    ``case.inspectionDate`` = "Date of inspection"
                    ``case.loadingCase`` = "Loading case ..."
               |}
     users = {|
               ``users.placeholder`` = "Search"
               ``button.addInspector`` = "+ New inspector"
               ``status.offline`` = "Offline"
               ``status.online`` = "Online"
               ``title.inspector`` = "Tekniker"
               ``title.role`` =  "Role"
               ``title.status`` = "Status"
               ``title.created`` = "Created"
               ``users.loading`` = "Loading users ..."
               ``role.agent`` = "Agent"
               ``role.supervisor`` = "Super Visor"
               ``status.free`` = "Ledig"
               ``status.busy`` = "Upptagen"
               ``checkbox.active`` = "Active"
               ``checkbox.inactive`` = "Inactive"
               ``title.title`` = "Title"
               ``title.email`` = "E-mail"
               ``title.customPositions`` = "Custom start/end positions"
               ``title.place`` = "Place"
               ``title.address`` = "Address"
               ``button.addPosition`` = "+ New position"
               ``user.loading`` = "Loading user ..."
               ``user.edit`` = "Edit user"
               ``user.save`` = "Save"
               ``user.fullName`` = "Full name"
               ``change.password`` = "Change password"
               ``change.password.subtitle`` = "Please provide your current password and the new one."
               ``error.password.required`` = "Password is required"
               ``error.password`` = "Password is required and should contain at least 8 characters, containing numeric and alphabetic characters"
               ``error.passwordConfirmation`` = "Passwords don't match"
               ``error.password.invalid`` = "Invalid password"
               ``currentPassword.placeholder`` = "Current password"
               ``newPassword.placeholder`` = "New password"
               ``repeatPassword.placeholder`` = "Repeat password"
               ``button.change.password`` = "Save"
               ``delete.position.confirmation.question`` = "Are you sure you want to delete position?"
               ``delete.user.confirmation.question`` = "Are you sure you want to delete user?"
          |}
     registerUser = {|
                       ``title`` = "Register user"
                       ``button.register`` = "Register"
                       ``user.firstName`` = "First name"
                       ``user.lastName`` = "Last name"
                       ``user.title`` = "Title"
                       ``title.email`` = "E-mail"
                      |}
     position = {|
                    ``column.place`` = "Place"
                    ``column.address`` = "Address"
                    ``title`` = "Positions"
                    ``button.addPosition`` = "+ Add position"
                    ``column.title.place`` = "Place"
                    ``column.title.address`` = "Address"
                    ``delete.position.confirmation.question`` = "Are you sure you want to delete position?"
                    ``loading.positions`` = "Loading positions ..."
               |}
     editPosition = {|
                    ``label.place`` = "Place"
                    ``label.address`` = "Address"
                    ``title`` = "Position details"
                    ``delete.position.confirmation.question`` = "Are you sure you want to delete position?"
               |}
     addPosition = {|
                   ``label.place`` = "Place"
                   ``label.address`` = "Address"
                   ``title`` = "Position details"
               |}
     editUserPosition = {|
                         ``label.place`` = "Place"
                         ``label.address`` = "Address"
                         ``title`` = "Position details"
                         ``delete.position.confirmation.question`` = "Are you sure you want to delete position?"
                    |}
     addUserPosition = {|
                         ``label.place`` = "Place"
                         ``label.address`` = "Address"
                         ``title`` = "Position details"
                         |}
     exportData = {|
                    ``placeholder.selectDate`` = "Select date"
                    ``button.result`` = "Show result"
                    ``button.update`` = "Update"
                    ``button.savePdf`` = "Save as PDF"
                    ``title.inspector`` = "Inspector"
                    ``title.numberOfJob`` = "Number of workplaces"
                    ``title.mileage`` = "Mileage"
                    ``title.time`` = "Required time"
                    ``table.total`` = "Total"
                    ``table.time.hours`` = "hours"
                    ``table.time.minutes`` = "min"
                    ``page.text`` = "Select date to view report"
                    ``loading`` = "Loading ..."
                    |}
     calendar = {|
                    ``title.monday`` = "Monday"
                    ``title.tuesday`` = "Tuesday"
                    ``title.wednesday`` = "Wednesday"
                    ``title.thursday`` = "Thursday"
                    ``title.friday`` = "Friday"
                    ``button.addingCase`` = "+ New case"
                    ``flatpickr.week`` = "Week"
                    ``loading.calendar``= "Loading calendar ..."
                    ``case.damageConstruction`` = "Construction"
                    ``case.damageMotor`` = "Motor"
                    ``case.damagePrevention`` = "Prevention"
                    ``case.damageOther``= "Other"
                    |}
     feedback = {|
                  ``text`` = "Help, support or suggestions?"
                  |}
     sendingSms = {|
                    ``phone.number`` = "Phone number"
                    ``button.send`` = "Send"
                    ``successfull`` = "Sms was successfully send."
                    ``error`` = ""
                    |}
     activateAccount = {|
                         ``password.placeholder`` = "Password"
                         ``password.repeat.placeholder`` = "Repeat password"
                         ``button.activate`` = "Activate account"
                         ``activation.inProgress`` = "Activating account ..."
                         ``activation.successful`` = "Your account activated successfully."
                         ``button.goto.signin`` = "Go to sign in page"
                         ``verifying.code`` = "Verifying code"
                         ``error.title`` = "Activate account"
                         ``already.activated`` = "Account already activated"
                         ``invalid.activation.code`` = "Invalid activation code"
                         |}
     client = {|
                ``user.anonymous`` = "Anonymous"
                ``menu.cases`` = "Cases"
                ``menu.staff`` = "Staff"
                ``menu.positions`` = "Start/End positions"
                ``menu.export`` = "Export"
                ``menu.logOut`` = "Log out"
                ``title.home`` = "Home"
                ``error.pageNotFound`` = "Page not found"
                |}
     common = {| ``delete.confirm`` = "Delete"
                 ``delete.cancel`` = "Cancel" |}
    |}

let private sv = {|
     signin = {|
                ``toast.title`` = "Logga in"
                ``button.login`` = "Logga in"
                ``email.placeholder`` = "E-postadress"
                ``password.placeholder`` = "Lösenord"
                ``title.forgotpassword`` = "Glömt lösenordet?"
                |}
     forgotPassword = {|
                ``toast.success.msg`` = "Instruktioner om hur du byter ditt lösenord har nu skickats till din e-postadress."
                ``toast.error.title`` = "Glömt ditt lösenord"
                ``title`` = "Glömt lösenordet?"
                ``back.link`` = "Tillbaka"
                ``button.send`` = "Skicka"
                |}
     changePassword = {|
                ``toast.success.msg`` = "Lösenordet är nu ändrat. Prova att logga in med ditt nya lösenord."
                ``title`` = "Byt lösenord"
                ``placeholder.newPassword`` = "Ange nytt lösenord"
                ``placeholder.repeatPassword`` = "Ange lösenordet igen"
                ``button.save`` = "Spara"
                ``signIn.link`` = "Tillbaka till inloggning"
                ``error.password`` = "Lösenord krävs och behöver innehålla minst 8 bokstäver"
                ``error.passwordConfirmation`` = "Lösenordet matchar inte"
                |};
     errors = {|
               ``server.exception`` = "Något gick fel. Felmeddelande: "
               ``server.validation`` = "Fält"
               ``server.itemNotFound``="Hittades inte"
               ``validation.isEmpty``="krävs"
               ``validation.tooLong`` = "måste vara mindre än"
               ``validation.tooSmall`` = "måste vara större än"
               ``validation.notUnique`` = "måste vara unikt"
               ``validation.isRequired`` = "krävs"
               ``validation.invalidFormat`` = "har ogiltigt format"
               ``domain.caseRegistered`` = "Ärendenummer saknas"
               ``server.remoteService`` = "Problem med tredjepartstjänster"
               ``domain.videoNotStarted`` = "Videoinspelning med angivet ID har inte startat ännu."
               ``domain.videoFileShouldBeCreated`` = "Ärendefil för videoinspelning med ID bör skapas innan."
               ``domain.passwordInvalid`` = "Ogiltig användare eller lösenord"
               ``domain.AccountLocked`` = "Kontot är låst"
               ``domain.AccountEmailAlreadyRegistered`` = "Konto med angiven e-postadress redan registrerat"
               ``domain.OldPasswordNotMatchToDbPassword`` = "Lösenordet är ogiltigt"
                |}
     addressBook = {|
                    ``group.ungroupedContacts`` = "Alla ogrupperade kontakter"
                    ``deviceId.placeholder`` = "Enhetsnummer"
                    ``selectCase.prompt`` = "Välj skada"
                    ``search.case.info`` = "Visad {{found}} av {{total}}"
                    ``search.case.noItemsFound`` = "Ingen ärenden hittades"
                    ``search.case.startTypeToSearch`` = "Starttyp för att söka"
                    ``search.case.placeholder`` = "Sök ärenden"
                    ``contextMenu.edit`` = "Redigera"
                    ``contextMenu.delete`` = "Radera"
                    ``contextMenu.newContact`` = " Ny kontakt"
                    ``contextMenu.newGroup`` = "Ny grupp"
                    ``input.placeholder.enterDeviceId`` = "Enhetsnummer"
                    ``table.contacts`` = "kontakter"
                    ``loading.groups`` = "Läser in information ..."
                    ``title.addressbook`` = "Adressbok"
                    ``delete.contact.confirmation.question`` = "Är du säker på att du vill radera kontakt?"
                    ``delete.group.confirmation.question`` = "Är du säker på att du vill radera grupp?"
                   |}
     emergencyCases = {|
                        ``title`` = "Bokade direktsamtal"
                        ``loading.cases``= "Läser in information ..."
                        ``case.damageConstruction`` = "Bygg"
                        ``case.damageMotor`` = "Motor"
                        ``case.damagePrevention`` = "Förebyggande"
                        ``case.damageOther``= "Övrig"
                       |}
     editContact = {|
                    ``search.group.info`` = "Visad {{found}} av {{total}}"
                    ``search.group.noItemsFound`` = "Ingen grupp hittades"
                    ``search.group.startTypeToSearch`` = "Starttyp för att söka"
                    ``search.group.placeholder`` = "Sök grupp"
                    ``title.editContact`` = "Redigera kontakt"
                    ``title.addContact`` = "Skapa kontakt"
                    ``placeholder.firstName`` = "Förnamn"
                    ``placeholder.lastName`` = "Efternamn"
                    ``placeholder.deviceId`` = "Enhetsnummer"
                    ``button.save`` = "Spara"
                   |}
     editGroup = {|
                    ``title.editGroup`` = "Redigera grupp"
                    ``title.addGroup`` = "Ny grupp"
                    ``placeholder.groupName`` = "Ange gruppnamn"
                    ``button.save`` = "Spara"
               |}
     selectMedia ={|
                    ``failed.access.to.devices`` = "Kunde inte få åtkomst till enheter. Fel:"
                    ``no.device`` = "Ingen enhet"
                    ``camera`` = "Kamera"
                    ``microphone`` = "Mikrofon"
                    ``audio.output`` = "Ljudkälla"
                    ``proceed.call`` = "Ring upp"
                    ``proceed.call.with.sharing.link`` = "Bjud in till ärende"
                    ``cancel.call`` = "Avbryt"
                    ``verifying.access.to.devices`` = "Verifierar åtkomst till dina enheter"
               |}
     videoCall ={|
                ``call.end`` = "Samtal avslutat"
                ``call.closeTab`` = "Stäng flik"
                ``call.toCaseDetails`` = "Visa skadan"
                ``call.joining`` = "Ansluter ..."
                ``call.waiting.of.joining`` = "Väntar på användare"
                ``call.button.leave`` = "Lämna samtal"
                ``call.device.notAllowed`` = "Tillgång till kamera eller ljud nekades."
                ``call.device.mediaOptionInvalid`` = "Kameran är upptagen eller upplösningen stöds inte (gammal webbläsarversion)."
                ``call.device.notFound`` = "Ingen enhet hittades."
                ``call.device.notSupported`` = "Webbläsaren stöder inte kamera och mikrofon."
                ``call.device.permissionDenied`` = "Tillstånd nekade."
                ``call.device.constraintNotSatisfied`` = "Inställningarna är olagliga (gammal webbläsarversion)."
                ``call.device.pluginNotInstalledProperly`` = "Webbläsaren stöder inte skärmdelning."
                ``call.device.undefined`` = "Okänt fel."
                ``title.email`` = "E-postadress"
                ``title.phone`` = "Telefonnummer"
                ``call.button.send.link`` = "Skicka inbjudan"
                ``call.sending.link.success`` = "Inbjudan skickades med lyckat resultat"
               |}
     recordingCall = {|
                        ``recording.video.permission`` = "Begär filminspelning"
                        ``recoring.video.allowed`` = "Filminspelning godkänd"
                        ``recording.start`` = "Börja filma"
                        ``recording.started`` = "Filminspelning påbörjad"
                        ``recording.successfull`` = "Filminspelning avslutad"
                        ``recording.denied`` = "Filminspelning nekades"
                        ``recording.request`` = "Nekades. Skicka en ny förfrågan?"
                |}
     photo = {|
                ``photo.allowed`` = "Bildtagning godkänd"
                ``photo.denied`` = "Bildtagning nekades"
                ``photo.taken`` = "Bilden sparades"
                ``photo.request.permission`` = "Begär bildtagning"
                ``photo.take`` = "Ta bild"
                ``photo.taking`` = "Tar bild"
                ``photo.denied.request.again`` = "Nekades. Skicka en ny förfrågan?"
                ``photo.failed.request.again`` = "Det gick inte att begära tillstånd. Försök igen?"
                ``photo.failed.take.again`` = "Bilden kunde inte sparas. Skicka en ny förfrågan?"
               |}
     case = {|
               ``caseStatus.new`` = "Ny"
               ``caseStatus.emergency`` = "Direktsamtal"
               ``caseStatus.inProgress`` = "Pågående"
               ``caseStatus.Closed`` = "Avslutat"
               ``button.addingCase``= "Nytt ärende"
               ``damage.construction``= "Bygg"
               ``damage.prevention`` = "Förebyggande"
               ``damage.motor``= "Motor"
               ``damage.other``= "Övrig"
               ``title.caseNumber``= "Ärendenummer"
               ``title.damageCause``= "Orsak"
               ``title.inspector``= "Tekniker"
               ``title.status``= "Status"
               ``title.date``= "Bokningsdatum"
               ``title.created.date``= "Ärende skapat"
               ``title.modified.date``= "Ärende uppdateras"
               ``loading.cases``= "Läser in information ..."
               |}
     caseDetail = {|
                    ``delete.case.confirmation.question`` = "Är du säker på att du vill radera ärendet?"
                    ``caseStatus.new`` = "Ny"
                    ``caseStatus.emergency`` = "Direktsamtal"
                    ``caseStatus.inProgress`` = "Pågående"
                    ``caseStatus.Closed`` = "Avslutat"
                    ``case.report`` = "Rapport"
                    ``case.call`` = "Ring upp"
                    ``case.policyholder`` = "Försäkringstagare"
                    ``case.partner`` = "Samarbetspartner"
                    ``case.damageConstruction`` = "Bygg"
                    ``case.damageMotor`` = "Motor"
                    ``case.damagePrevention`` = "Förebyggande"
                    ``case.damageOther``= "Övrig"
                    ``case.regnumber`` = "Regnummer"
                    ``case.number`` = "Ärendenummer"
                    ``case.damageType`` = "Orsak"
                    ``case.address`` = "Adress"
                    ``case.deviceId`` = "Enhetsnummer"
                    ``case.booking`` = "Bokad av"
                    ``case.inspector`` = "Tekniker"
                    ``case.date`` = "Bokningsdatum"
                    ``case.inspectionDate`` = "Inspektionsdatum"
                    ``case.duration`` = "Samtalstid: "
                    ``case.comment`` = "Lagg till kommentar"
                    ``case.created`` = "Ärendet skapades"
                    ``case.loadingFiles`` = "Läser in information ..."
                    ``case.information`` = "Information"
                    ``case.gallery`` = "Galleri"
                    ``case.historyAndComments`` = "Historik & Kommentarer"
                    ``case.loading`` = "Läser in information ..."
                    ``case.media.download`` = "Ladda ner"
                    ``case.mediaAddress.processing`` = "Spårar adress"
                    ``case.mediaAddress.Unknown``= "Adress kan ej lokaliseras"
                    ``case.mediaAddress.NoLocation`` = "Ingen GPS-position lokaliserad"
                    ``warning.no.media`` = "Inga mediefiler"
                    ``error.downloading.zipFile`` = "ZIP-fil kan inte skapas"
                    ``edit.image.failed.save`` = "Misslyckades med att spara redigerad bild"
                    ``delete.media.confirmation.question`` = "Är du säker på att du vill radera mediafilen?"
                    ``delete.media.confirmation.button.label`` = "Radera"
                    |}
     registerCase = {|
                      ``case.report`` = "Nytt ärende"
                      ``button.register`` = "Skapa ärende"
                      ``case.regnumber`` = "Regnummer"
                      ``case.number`` = "Ärendenummer"
                      ``case.damageType`` = "Orsak"
                      ``case.damageConstruction`` = "Bygg"
                      ``case.damageMotor`` = "Motor"
                      ``case.damagePrevention`` = "Förebyggande"
                      ``case.damageOther``= "Övrig"
                      ``case.address`` = "Adress"
                      ``case.deviceId`` = "Enhetsnummer"
                      ``case.inspector`` = "Tekniker"
                      ``input.text.noInspectors`` = "Inte tilldelat"
                      ``case.date`` = "Bokningsdatum"
                      ``case.inspectionDate`` = "Inspektionsdatum"
                      |}
     editCase = {|
                    ``case.damageConstruction`` = "Bygg"
                    ``case.damageMotor`` = "Motor"
                    ``case.damagePrevention`` = "Förebyggande"
                    ``case.damageOther``= "Övrig"
                    ``case.edit`` = "Redigera ärende"
                    ``case.save`` = "Spara"
                    ``case.regnumber`` = "Regnummer"
                    ``case.number`` = "Ärendenummer"
                    ``case.damageType`` = "Orsak"
                    ``case.address`` = "Adress"
                    ``case.deviceId`` = "Enhetsnummer"
                    ``case.booking`` = "Bokad av"
                    ``case.inspector`` = "Tekniker"
                    ``case.date`` = "Bokningsdatum"
                    ``case.inspectionDate`` = "Inspektionsdatum"
                    ``case.loadingCase`` = "Läser in information ..."
               |}
     users = {|
               ``users.placeholder`` = "Sök"
               ``button.addInspector`` = "+ Ny tekniker"
               ``status.offline`` = "Offline"
               ``status.online`` = "Online"
               ``title.inspector`` = "Tekniker"
               ``title.role`` =  "Roll"
               ``title.status`` = "Status"
               ``title.created`` = "Skapad"
               ``users.loading`` = "Läser in information ..."
               ``role.agent`` = "Tekniker"
               ``role.supervisor`` = "Administratör"
               ``status.free`` = "Ledig"
               ``status.busy`` = "Upptagen"
               ``checkbox.active`` = "Aktiv"
               ``checkbox.inactive`` = "Inaktiv"
               ``title.title`` = "Titel"
               ``title.email`` = "E-postadress"
               ``title.customPositions`` = "Specialanpassade start/slut positioner"
               ``title.place`` = "Plats"
               ``title.address`` = "Adress"
               ``button.addPosition`` = "Lägg till position"
               ``user.loading`` = "Läser in information ..."
               ``user.edit`` = "Redigera användare"
               ``user.save`` = "Spara"
               ``user.fullName`` = "Fullständiga namn"
               ``change.password`` = "Byt lösenord"
               ``change.password.subtitle`` = "Ange ditt nuvarande lösenord och sedan ett nytt som också behöver upprepas. Klicka sedan på knappen - Byt lösenord."
               ``error.password.required`` = "Lösenord krävs"
               ``error.password`` = "Lösenord krävs och behöver innehålla minst 8 bokstäver och/eller siffror."
               ``error.passwordConfirmation`` = "Lösenordet matchar inte"
               ``error.password.invalid`` = "Felaktigt lösenord"
               ``currentPassword.placeholder`` = "Nuvarande lösenord"
               ``newPassword.placeholder`` = "Nytt lösernord"
               ``repeatPassword.placeholder`` = "Upprepa lösernord"
               ``button.change.password`` = "Spara"
               ``delete.position.confirmation.question`` = "Är du säker på att du vill radera position?"
               ``delete.user.confirmation.question`` = "Är du säker på att du vill radera användare?"
          |}
     registerUser = {|
                       ``title`` = "NY ANVÄNDARE"
                       ``button.register`` = "Skapa"
                       ``user.firstName`` = "Förnamn"
                       ``user.lastName`` = "Efternamn"
                       ``user.title`` = "Titel"
                       ``title.email`` = "E-postadress"
                      |}
     position = {|
                    ``column.place`` = "Plats"
                    ``column.address`` = "Adress"
                    ``title`` = "Positioner"
                    ``button.addPosition`` = "Lägg till position"
                    ``column.title.place`` = "Plats"
                    ``column.title.address`` = "Adress"
                    ``delete.position.confirmation.question`` = "Är du säker på att du vill radera position?"
                    ``loading.positions`` = "Läser in information ..."
               |}
     editPosition = {|
                    ``label.place`` = "Plats"
                    ``label.address`` = "Adress"
                    ``title`` = "Platsdetaljer"
                    ``delete.position.confirmation.question`` = "Är du säker på att du vill radera position?"
               |}
     addPosition = {|
                   ``label.place`` = "Plats"
                   ``label.address`` = "Adress"
                   ``title`` = "Platsdetaljer"
               |}
     editUserPosition = {|
                         ``label.place`` = "Plats"
                         ``label.address`` = "Adress"
                         ``title`` = "Platsdetaljer"
                         ``delete.position.confirmation.question`` = "Är du säker på att du vill radera position?"
                    |}
     addUserPosition = {|
                         ``label.place`` = "Plats"
                         ``label.address`` = "Adress"
                         ``title`` = "Platsdetaljer"
                         |}
     exportData = {|
                    ``placeholder.selectDate`` = "Välj datum"
                    ``button.result`` = "Visa resultat"
                    ``button.update`` = "Uppdatera"
                    ``button.savePdf`` = "Spara som PDF"
                    ``title.inspector`` = "Tekniker"
                    ``title.numberOfJob`` = "Antal jobb"
                    ``title.mileage`` = "Körda km"
                    ``title.time`` = "Tidsåtgång"
                    ``table.total`` = "Total"
                    ``table.time.hours`` = "timmar"
                    ``table.time.minutes`` = "min"
                    ``page.text`` = "Välj datum för att se rapport"
                    ``loading`` = "Läser in information ..."
                    |}
     calendar = {|
                    ``title.monday``="Måndag"
                    ``title.tuesday``="Tisdag"
                    ``title.wednesday``="Onsdag"
                    ``title.thursday``="Torsdag"
                    ``title.friday``="Fredag"
                    ``button.addingCase`` = "Nytt ärende"
                    ``flatpickr.week`` = "Vecka"
                    ``loading.calendar``= "Läser in information ..."
                    ``case.damageConstruction`` = "Bygg"
                    ``case.damageMotor`` = "Motor"
                    ``case.damagePrevention`` = "Förebyggande"
                    ``case.damageOther``= "Övrig"
                    |}
     feedback = {|
                  ``text`` = "Hjälp, support eller förslag?"
                  |}
     sendingSms = {|
                    ``phone.number`` = "Telefonnummer"
                    ``button.send`` = "Skicka"
                    ``successfull`` = "Meddelandet skickades med lyckat resultat"
                    ``error`` = "Det gick inte att skicka meddelandet. "
                    |}
     activateAccount = {|
                         ``password.placeholder`` = "Lösenord"
                         ``password.repeat.placeholder`` = "Upprepa lösernord"
                         ``button.activate`` = "Aktivera konto"
                         ``activation.inProgress`` = "Kontot aktiveras"
                         ``activation.successful`` = "Kontot har aktiverats."
                         ``button.goto.signin`` = "Inloggningssidan"
                         ``verifying.code`` = "Verifierar kod"
                         ``error.title`` = "Aktivera konto"
                         ``already.activated`` = "Kontot är redan aktiverat"
                         ``invalid.activation.code`` = "Ogiltig aktiveringskod"
                         |}
     client = {|
                ``user.anonymous`` = "Anonym"
                ``menu.cases`` = "Ärenden"
                ``menu.staff`` = "Personal"
                ``menu.positions`` = "Start/Slut positioner"
                ``menu.export`` = "Exportera"
                ``menu.logOut`` = "Logga ut"
                ``title.home`` = "Hem"
                ``error.pageNotFound`` = "Sidan hittas inte"
                |}
     common = {| ``delete.confirm`` = "Radera"
                 ``delete.cancel`` = "Avbryt" |}
     |}

let resources = {| en = en; sv = sv |}